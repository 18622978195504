import jsPDF from 'jspdf';
import { useState } from 'react';
import { toast } from 'react-toastify';

const useShare = () => {
  const [isLoadingPdf, setIsLoadingPdf] = useState(false);
  const [getFile, setGetFile] = useState();
  const sharePdf = async (id, filename, pagewidth) => {
    setIsLoadingPdf(true);
    const source = document.getElementById(id);
    const windowWidth = document.getElementById(id).offsetWidth;

    const pdf = new jsPDF();

    const addPageNumbers = (pdf, totalPages) => {
      for (let i = 1; i <= totalPages; i++) {
        pdf.setPage(i);
        pdf.setFontSize(8);
        pdf.text(
          5,
          pdf.internal.pageSize.height - 5,
          `Page ${i} of ${totalPages}`,
        );
      }
    };

    await pdf.html(source, {
      callback: function (doc) {
        addPageNumbers(doc, doc.getNumberOfPages());
        // send natigator to share
        // doc.save();

        const pdfBlob = doc.output('blob');

        const files = [
          new File([pdfBlob], `${filename}.pdf`, { type: 'application/pdf' }),
        ];
        setGetFile(files);
        setIsLoadingPdf(false);
      },
      x: 0,
      y: 0,
      margin: [10, 10, 10, 10],
      autoPaging: 'text',
      width: 190,
      windowWidth: pagewidth
        ? pagewidth
        : windowWidth < 900
        ? windowWidth + 100
        : windowWidth + 400,
    });
  };

  const navigatorShare = async (files) => {
    if (files?.length < 1) {
      toast.warn(`No files selected`);
    }
    if (!navigator?.canShare) {
      toast.warn(`Your browser doesn't support the Web Share API.`);
    }
    if (navigator?.canShare({ files })) {
      try {
        await navigator?.share({
          files,
          title: 'Pdf !',
        });
        setGetFile();
        // console.log(`Shared!`);
      } catch (error) {
        toast.error(`Error: ${error.message}`);
      }
    } else {
      toast.warn(`Your system doesn't support sharing these files.`);
    }
  };
  return { sharePdf, isLoadingPdf, getFile, navigatorShare };
};

export default useShare;
