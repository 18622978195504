import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {
  Autocomplete,
  Button,
  Container,
  IconButton,
  InputAdornment,
  Paper,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import React from 'react';
import { Link, useNavigate, useOutletContext } from 'react-router-dom';
import { toast } from 'react-toastify';
import useAuth from '../../hooks/useAuth';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import useHeader from '../../hooks/useHeader';
import getMonthWeekRange from '../../utils/getMonthWeekRange';
import roundToDigit from '../../utils/roundToDigit';

function AddLiftingPlan() {
  const navigate = useNavigate();
  const [refetch] = useOutletContext();
  const axiosPrivate = useAxiosPrivate();
  const { setHeader } = useHeader();
  const { user, userId } = useAuth();

  const [isLoading, setIsLoading] = React.useState(false);
  const [isDateError, setIsDateError] = React.useState(false);

  const [customerList, setCustomerList] = React.useState([]);
  const [customer, setCustomer] = React.useState({});
  const [month, setMonth] = React.useState(dayjs());
  const [entries, setEntries] = React.useState({});

  React.useEffect(() => {
    setHeader({ title: 'Add Lifting Plan' });
  }, []);

  React.useEffect(() => {
    axiosPrivate
      .get(
        `/api/v1/customer?limit=none&isActive=true&sort=code&parentTerritory=${user?.employee?.territory?.id}`,
      )
      .then((res) => {
        setCustomerList(res?.data?.data);
      })
      .catch((error) => toast.warn(error?.response?.data?.message));
  }, [user?.employee?.territory?.id]);

  // Set entries as per month-weeks
  React.useEffect(() => {
    const startM = month?.startOf('month')?.toDate();
    const endM = month?.endOf('month')?.toDate();

    const entriesX = {
      wholeMonth: {
        timeStart: startM,
        timeEnd: endM,
        commitmentAmount: entries?.wholeMonth?.commitmentAmount || 0,
      },
    };

    const mw = Object.values(getMonthWeekRange(startM, endM))[0];

    Object.entries(mw || {}).forEach(([numW, { start: startW, end: endW }]) => {
      if ((numW, startW, endW)) {
        const weekKey = `Week ${numW}`;

        entriesX[weekKey] = {
          timeStart: startW,
          timeEnd: endW,
          commitmentAmount: entries?.[weekKey]?.commitmentAmount || 0,
        };
      }
    });

    setEntries(entriesX);
  }, [month?.valueOf()]);

  const handleAmountChange = (key, value) => {
    const entriesX = { ...entries };
    entriesX[key].commitmentAmount = value;
    setEntries(entriesX);
  };

  const handleSubmit = () => {
    let isError = false;
    const data = [];

    Object.values(entries).forEach((v) => {
      if (!v?.timeStart || !v?.timeEnd) {
        isError = true;
      }
      if (v?.commitmentAmount < 0) {
        isError = true;
      }
      data.push({
        employee: userId,
        customer: customer?.id,
        timeStart: v?.timeStart,
        timeEnd: v?.timeEnd,
        commitmentAmount: v?.commitmentAmount,
      });
    });

    if (isError) {
      toast.warn('Please enter planned amount for all slots', {
        position: 'bottom-center',
      });
    } else {
      setIsLoading(true);

      axiosPrivate({
        method: 'post',
        url: '/api/v1/lifting-commitment/insert',
        data,
      })
        .then((res) => {
          toast.success(res?.data?.message);
          refetch();
          navigate('/lifting-plan-details');
        })
        .catch((error) => toast.warn(error?.response?.data?.message))
        .finally(() => setIsLoading(false));
    }
  };

  return (
    <Container maxWidth="sm">
      <IconButton
        component={Link}
        to="/lifting-plan-details"
        aria-label="Go back"
        edge="start"
        sx={{ mb: 1 }}>
        <ArrowBackIcon />
      </IconButton>

      <Stack
        component={Paper}
        sx={{ px: 2, py: 3, gap: 3, alignItems: 'center' }}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label="Select Month"
            disabled={isLoading}
            fullWidth
            inputFormat="MMMM YYYY"
            views={['month', 'year']}
            value={month}
            onError={(reason) => setIsDateError(Boolean(reason))}
            onChange={(newValue) => setMonth(newValue)}
            renderInput={(params) => <TextField {...params} fullWidth />}
          />
        </LocalizationProvider>

        <Autocomplete
          disabled={isLoading || customerList?.length < 1}
          fullWidth
          options={customerList}
          value={customer}
          isOptionEqualToValue={(option, value) => value?.id === option?.id}
          onChange={(event, value) => setCustomer(value)}
          getOptionLabel={(option) => option?.nameCode || ''}
          renderInput={(params) => (
            <TextField {...params} label="Select Customer" />
          )}
        />

        <Typography variant="subtitle1" color="primary.main">
          Lifting Plans for {month.format('MMMM YYYY')}
        </Typography>

        {Object.entries(entries).map(([key, value]) => {
          if (key === 'wholeMonth') {
            return null;
          }

          const startW = dayjs(value?.timeStart).format('ddd, MMM D');
          const endW = dayjs(value?.timeEnd).format('ddd, MMM D');

          if (
            !value?.timeStart ||
            !value?.timeEnd ||
            startW === 'Invalid Date' ||
            endW === 'Invalid Date'
          ) {
            return null;
          }

          const diffDays = roundToDigit(
            dayjs(value?.timeEnd).diff(dayjs(value?.timeStart), 'day', true),
          );

          let daysLabel = '';
          if (diffDays === 1) {
            daysLabel = `(${diffDays} day)`;
          } else if (diffDays > 1 && diffDays < 7) {
            daysLabel = `(${diffDays} days)`;
          } else if (diffDays < 1 && diffDays > 7) {
            return null;
          }

          return (
            <Stack
              key={key}
              component={Paper}
              variant="outlined"
              sx={{ width: '100%', px: 1, py: 2, gap: 2 }}>
              <Typography variant="subtitle2" color="primary.main">
                <b>{key}:</b> {startW} - {endW} {daysLabel}
              </Typography>

              <TextField
                disabled={isLoading}
                label="Planned Collection Amount"
                fullWidth
                type="number"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">Tk</InputAdornment>
                  ),
                }}
                onWheel={(e) => e.target.blur()}
                value={entries?.[key]?.commitmentAmount}
                onChange={(e) => handleAmountChange(key, e.target.value)}
              />
            </Stack>
          );
        })}

        {entries?.wholeMonth ? (
          <Stack
            component={Paper}
            variant="outlined"
            sx={{ width: '100%', px: 1, py: 2, gap: 2 }}>
            <Typography variant="subtitle2" color="primary.main">
              Whole month of {month.format('MMM YYYY')}
            </Typography>

            <TextField
              disabled={isLoading}
              label="Planned Collection Amount"
              fullWidth
              type="number"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">Tk</InputAdornment>
                ),
              }}
              onWheel={(e) => e.target.blur()}
              value={entries?.wholeMonth?.commitmentAmount}
              onChange={(e) => handleAmountChange('wholeMonth', e.target.value)}
            />
          </Stack>
        ) : null}

        <Button
          disabled={isLoading || isDateError}
          fullWidth
          variant="contained"
          size="large"
          onClick={handleSubmit}>
          Submit
        </Button>
      </Stack>
    </Container>
  );
}

export default AddLiftingPlan;
