import React, { useEffect, useState } from 'react';
import { Autocomplete, Stack, TextField, Typography } from '@mui/material';
import { toast } from 'react-toastify';
import Button from '../../../component/Button';
import Modals from '../../../component/Modals';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';

function EditModal({
  showModal,
  setShowModal,
  editData,
  setEditData,
  refetch,
}) {
  const axiosPrivate = useAxiosPrivate();

  const [employee, setEmployee] = useState({});
  const [customer, setCustomer] = useState({});
  const [allEmployees, setAllEmployees] = useState([]);
  const [allCustomers, setAllCustomers] = useState([]);
  const [hasCustomer, setHasCustomer] = useState(false);

  // get all employees
  useEffect(() => {
    axiosPrivate
      .get('/api/v1/employee?populate=yes&limit=none&isActive=true')
      .then((res) => {
        setAllEmployees(res?.data?.data);
      })
      .catch((error) => console.warn(error));
  }, []);

  // get all customers
  useEffect(() => {
    axiosPrivate
      .get('/api/v1/customer?populate=yes&limit=none&isActive=true')
      .then((res) => {
        setAllCustomers(res?.data?.data);
      })
      .catch((error) => console.warn(error));
  }, []);

  // set employee & customer
  useEffect(() => {
    setEmployee(editData?.employee);
    setCustomer(editData?.customer);
  }, [editData?.id]);

  // set hasCustomer
  useEffect(() => {
    if (employee?.role?.employeeRole === 'SD') {
      setHasCustomer(true);
    } else {
      setHasCustomer(false);
      setCustomer({});
    }
  }, [employee?.role?.employeeRole]);

  // close modal handler
  const handleClose = () => {
    setEmployee({});
    setCustomer({});
    setEditData({});
    setShowModal(false);
  };

  // update handler
  const handleSubmit = () => {
    axiosPrivate
      .post(`/api/v1/user/${editData?.id}`, {
        userType: 'employee',
        employee: employee?.id,
        customer: customer?.id,
      })
      .then((res) => {
        refetch();
        handleClose();
        toast.success(res?.data?.message);
      })
      .catch((error) => {
        toast.warn(error?.response?.data?.message);
      });
  };

  return (
    <Modals
      title="Set Employee Profile For User"
      show={showModal}
      onHide={handleClose}
      form>
      <Stack direction="column" gap={2}>
        <Typography
          sx={{ fontWeight: 'bold', fontSize: '20px', textAlign: 'center' }}>
          User's login info
        </Typography>
        <table className="upTable">
          <tr>
            <th>DB_ID</th>
            <th>:&nbsp;</th>
            <td>{editData?.id}</td>
          </tr>
          <tr>
            <th style={{ minWidth: 68 }}>Phone</th>
            <th>:&nbsp;</th>
            <td>{editData?.phone}</td>
          </tr>
          <tr>
            <th>Email</th>
            <th>:&nbsp;</th>
            <td>{editData?.email}</td>
          </tr>
        </table>

        <hr />

        <Typography
          sx={{ fontWeight: 'bold', fontSize: '20px', textAlign: 'center' }}>
          Employee info
        </Typography>
        <Autocomplete
          fullWidth
          disabled={allEmployees.length < 1}
          options={allEmployees}
          value={employee}
          isOptionEqualToValue={(option, value) => value?.id === option?.id}
          onChange={(event, value) => setEmployee(value)}
          getOptionLabel={(option) =>
            option?.name
              ? `${option?.name} (${option?.code}) - ${option?.territory?.name}`
              : ''
          }
          renderInput={(params) => (
            <TextField {...params} fullWidth label="User's Employee Profile" />
          )}
        />
        {employee?.id ? (
          <table border={0} className="upTable">
            <tr>
              <th>DB_ID</th>
              <th>:&nbsp;</th>
              <td>{employee?.id}</td>
            </tr>
            <tr>
              <th style={{ minWidth: 68 }}>Name</th>
              <th>:&nbsp;</th>
              <td>{employee?.name}</td>
            </tr>
            <tr>
              <th>Code</th>
              <th>:&nbsp;</th>
              <td>{employee?.code}</td>
            </tr>
            <tr>
              <th>Phone</th>
              <th>:&nbsp;</th>
              <td>{employee?.contactNumber}</td>
            </tr>
            <tr>
              <th>Email</th>
              <th>:&nbsp;</th>
              <td>{employee?.email}</td>
            </tr>
            <tr>
              <th>Role</th>
              <th>:&nbsp;</th>
              <td>{employee?.role?.employeeRole}</td>
            </tr>
            <tr>
              <th>Territory</th>
              <th>:&nbsp;</th>
              <td>{employee?.territory?.nameCode}</td>
            </tr>
          </table>
        ) : null}

        {hasCustomer ? (
          <>
            <hr />

            <Typography
              sx={{
                fontWeight: 'bold',
                fontSize: '20px',
                textAlign: 'center',
              }}>
              Customer info
            </Typography>
            <Autocomplete
              fullWidth
              disabled={allCustomers.length < 1}
              options={allCustomers}
              value={customer}
              isOptionEqualToValue={(option, value) => value?.id === option?.id}
              onChange={(event, value) => setCustomer(value)}
              getOptionLabel={(option) =>
                option?.name
                  ? `${option?.name} (${option?.code}) - ${option?.territory?.name}`
                  : ''
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  label="User's Customer Profile"
                />
              )}
            />
            {customer?.id ? (
              <table border={0} className="upTable">
                <tr>
                  <th>DB_ID</th>
                  <th>:&nbsp;</th>
                  <td>{customer?.id}</td>
                </tr>
                <tr>
                  <th style={{ minWidth: 68 }}>Name</th>
                  <th>:&nbsp;</th>
                  <td>{customer?.name}</td>
                </tr>
                <tr>
                  <th>Code</th>
                  <th>:&nbsp;</th>
                  <td>{customer?.code}</td>
                </tr>
                <tr>
                  <th>Phone</th>
                  <th>:&nbsp;</th>
                  <td>{customer?.contact || customer?.personalContact}</td>
                </tr>
                <tr>
                  <th>Email</th>
                  <th>:&nbsp;</th>
                  <td>{customer?.email}</td>
                </tr>
                <tr>
                  <th>Type</th>
                  <th>:&nbsp;</th>
                  <td>{customer?.customerType?.customerType}</td>
                </tr>
                <tr>
                  <th>Territory</th>
                  <th>:&nbsp;</th>
                  <td>{customer?.territory?.nameCode}</td>
                </tr>
              </table>
            ) : null}
          </>
        ) : null}

        <Button type="submit" text="Save" onClick={handleSubmit} />
      </Stack>
    </Modals>
  );
}

export default EditModal;
