import { useEffect, useState } from 'react';
import FindInPageIcon from '@mui/icons-material/FindInPageOutlined';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import GridViewIcon from '@mui/icons-material/GridView';
import SquareIcon from '@mui/icons-material/Square';
import {
  Autocomplete,
  CircularProgress,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  ToggleButton,
  Typography,
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { useQuery } from 'react-query';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import useExpiredSession from '../../hooks/useExpiredSession';
import useHeader from '../../hooks/useHeader';
import { TotalSKUColors } from '../../staticData/Colors';
import HeadingTitle from '../HeadingTitle';

export default function CollectionAnalysis() {
  const axiosPrivate = useAxiosPrivate();
  const expired = useExpiredSession();
  const { setHeader } = useHeader();

  const [timeline, setTimeline] = useState(4);
  const [date, setDate] = useState(dayjs());
  const [territoryList, setTerritoryList] = useState([]);
  const [territory, setTerritory] = useState({});
  const [territoryType, setTerritoryType] = useState({
    name: 'Region',
    id: '63aaa3c505e4dc7daa1a85ce',
  });
  const [largeView, setLargeView] = useState(false);
  const [isFullScreen, setIsFullScreen] = useState();

  useEffect(() => {
    const t = territory.name
      ? territoryType.name + ': ' + territory.name
      : territoryType.name;
    setHeader({ title: `Collections - ${t}` });
  }, [territoryType.name, territory.name]);

  useEffect(() => {
    axiosPrivate
      .get(`/api/v1/territory?limit=none&territoryType=${territoryType?.id}`)
      .then((res) => setTerritoryList(res?.data?.data))
      .catch((error) => console.error(error));
  }, [territoryType?.id]);

  const { data, isLoading, isError, error } = useQuery(
    ['col-dashboard', territory?.id, date.format('YYYY-MM-DD'), timeline],
    ({ queryKey }) => {
      const [key, territory, month, compareMonths] = queryKey;
      if (Object.keys(territory).length !== 0) {
        return axiosPrivate
          .get(
            `/api/v1/report/dashboard-collection?territory=${territory}&month=${month}&compareMonths=${compareMonths}`,
          )
          .then((d) => d?.data?.data);
      }
    },
  );

  const toggleFullScreen = () => {
    const element = document.getElementById('container');
    const isFullScreenView = document.fullscreenElement;
    // console.log(isFullScreenView);
    if (isFullScreenView) {
      document.exitFullscreen();
      setIsFullScreen(false);
    } else {
      element.requestFullscreen();
      setIsFullScreen(true);
    }
  };

  if (isError) {
    expired(error);
  }

  return (
    <Stack
      gap={2}
      id="container"
      sx={{
        overflowY: isFullScreen ? 'scroll' : 'initial',
        p: isFullScreen ? 2 : 0,
        pt: isFullScreen ? 4 : 0,
        background: '#e3e7ef',
      }}>
      <Stack
        component={Paper}
        gap={2}
        alignItems="center"
        sx={{ p: 3 }}
        position="relative">
        <HeadingTitle variant={0}>Collection Analysis</HeadingTitle>
        {/* filter */}
        <Stack direction={{ md: 'row' }} gap={2}>
          <Autocomplete
            sx={{ width: { md: 150 } }}
            disableClearable
            size="small"
            disablePortal
            options={[
              { name: 'Region', id: '63aaa3c505e4dc7daa1a85ce' },
              { name: 'HQ', id: '63aaa46405e4dc7daa1a8648' },
            ]}
            getOptionLabel={(option) => option.name || ''}
            value={territoryType}
            onChange={(event, value) => {
              setTerritoryType(value);
              setTerritory({});
            }}
            renderInput={(params) => (
              <TextField {...params} label="Location Type" />
            )}
          />
          <Autocomplete
            loading
            sx={{ width: { md: 150 } }}
            disableClearable
            size="small"
            disablePortal
            options={territoryList}
            getOptionLabel={(option) => option.name || ''}
            value={territory}
            onChange={(event, value) => setTerritory(value)}
            renderInput={(params) => (
              <TextField {...params} label={territoryType.name} />
            )}
          />
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Select Month"
              inputFormat="MMM YYYY"
              views={['month', 'year']}
              value={date}
              onChange={(newValue) => setDate(newValue || null)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  sx={{ width: { md: 160 } }}
                />
              )}
            />
          </LocalizationProvider>
          <Autocomplete
            loading
            sx={{ width: { md: 170 } }}
            disableClearable
            size="small"
            disablePortal
            options={[4, 6, 8, 12]}
            getOptionLabel={(option) => 'Last ' + option + ' months' || ''}
            value={timeline}
            onChange={(event, value) => setTimeline(value)}
            renderInput={(params) => (
              <TextField {...params} label="Choose Timeline" />
            )}
          />
          {/* <FormControl size="small">
            <InputLabel>Choose Timeline</InputLabel>
            <Select
              value={timeline}
              label="Choose Timeline"
              onChange={(e) => setTimeline(e.target.value)}>
              <MenuItem value={4}>Last 4 months</MenuItem>
              <MenuItem value={6}>Last 6 months</MenuItem>
              <MenuItem value={8}>Last 8 months</MenuItem>
              <MenuItem value={12}>Last 12 months</MenuItem>
            </Select>
          </FormControl> */}
          <ToggleButton
            sx={{ display: { lg: 'flex', xs: 'none' } }}
            size="small"
            value="check"
            selected={largeView}
            onChange={() => {
              setLargeView(!largeView);
            }}>
            <GridViewIcon />
          </ToggleButton>
          <IconButton
            onClick={toggleFullScreen}
            sx={{
              display: { lg: 'flex', xs: 'none' },
              position: 'absolute',
              top: 5,
              right: 5,
            }}>
            {isFullScreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
          </IconButton>
        </Stack>
      </Stack>

      {isLoading ? (
        <Stack
          component={Paper}
          justifyContent="center"
          alignItems="center"
          height={{ xs: '30vh', md: '50vh' }}
          gap={2}>
          <CircularProgress />
          <Typography color="text.disabled">
            Loading{' '}
            <b>
              {territory.name} {territoryType.name}
            </b>{' '}
            Data
          </Typography>
        </Stack>
      ) : (
        <>
          {Object.keys(territory)?.length < 1 ? (
            <Stack
              component={Paper}
              height={{ xs: '30vh', md: '50vh' }}
              justifyContent="center"
              alignItems="center">
              <Typography fontSize={{ xs: 18, md: 35 }} fontWeight={800}>
                Please Select a {territoryType.name}
              </Typography>
            </Stack>
          ) : (
            <>
              {/* PLAN ACHIEVEMENT RATE */}
              <Paper sx={{ p: 2, width: '100%' }}>
                <Typography
                  fontSize={18}
                  fontWeight={700}
                  textAlign="center"
                  textTransform="uppercase"
                  pb={1}>
                  {data?.planAchievementRate?.message}
                </Typography>
                {!data?.planAchievementRate?.parentLocation?.liftingPlan ? (
                  <NoData />
                ) : (
                  <TableContainer sx={tableStyle}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell></TableCell>
                          <TableCell>Lifting Plan</TableCell>
                          <TableCell>Collection Amount</TableCell>
                          <TableCell>Lifting Amount</TableCell>
                          <TableCell>Collection Achievement</TableCell>
                          <TableCell>Lifting Achievement</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell>{territoryType?.name}</TableCell>
                          <TableCell>
                            {
                              data?.planAchievementRate?.parentLocation
                                ?.liftingPlan
                            }
                          </TableCell>
                          <TableCell>
                            {
                              data?.planAchievementRate?.parentLocation
                                ?.collectionAmount
                            }
                          </TableCell>
                          <TableCell>
                            {
                              data?.planAchievementRate?.parentLocation
                                ?.liftingAmount
                            }
                          </TableCell>
                          <TableCell>
                            {
                              data?.planAchievementRate?.parentLocation
                                ?.collectionAchievement
                            }
                            %
                          </TableCell>
                          <TableCell>
                            {
                              data?.planAchievementRate?.parentLocation
                                ?.liftingAchievement
                            }
                            %
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>No. of Customers</TableCell>
                          <TableCell>
                            {
                              data?.planAchievementRate?.numberOfCustomer
                                ?.liftingPlan
                            }
                          </TableCell>
                          <TableCell>
                            {
                              data?.planAchievementRate?.numberOfCustomer
                                ?.collectionAmount
                            }
                          </TableCell>
                          <TableCell>
                            {
                              data?.planAchievementRate?.numberOfCustomer
                                ?.liftingAmount
                            }
                          </TableCell>
                          <TableCell>
                            {
                              data?.planAchievementRate?.numberOfCustomer
                                ?.collectionAchievement
                            }
                            %
                          </TableCell>
                          <TableCell>
                            {
                              data?.planAchievementRate?.numberOfCustomer
                                ?.liftingAchievement
                            }
                            %
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Per Customer</TableCell>
                          <TableCell>
                            {
                              data?.planAchievementRate?.perCustomer
                                ?.liftingPlan
                            }
                          </TableCell>
                          <TableCell>
                            {
                              data?.planAchievementRate?.perCustomer
                                ?.collectionAmount
                            }
                          </TableCell>
                          <TableCell>
                            {
                              data?.planAchievementRate?.perCustomer
                                ?.liftingAmount
                            }
                          </TableCell>
                          <TableCell>
                            {
                              data?.planAchievementRate?.perCustomer
                                ?.collectionAchievement
                            }
                            %
                          </TableCell>
                          <TableCell>
                            {
                              data?.planAchievementRate?.perCustomer
                                ?.liftingAchievement
                            }
                            %
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                )}
              </Paper>
              {/* SALES & COLLECTION PER CUSTOMER */}
              <Paper sx={{ p: 2, width: '100%' }}>
                <Typography
                  fontSize={18}
                  fontWeight={700}
                  textAlign="center"
                  textTransform="uppercase"
                  pb={1}>
                  {data?.salesCollectionPerCustomer?.message}
                </Typography>
                {data?.salesCollectionPerCustomer?.data?.length < 1 ? (
                  <NoData />
                ) : (
                  <TableContainer sx={tableStyle}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell></TableCell>
                          {data?.salesCollectionPerCustomer?.months?.map(
                            (i) => (
                              <TableCell key={i}>{i}</TableCell>
                            ),
                          )}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell>Total Collection</TableCell>
                          {data?.salesCollectionPerCustomer?.months?.map(
                            (i) => (
                              <TableCell key={i}>
                                {
                                  data?.salesCollectionPerCustomer?.data?.[i]
                                    ?.totalCollection
                                }
                              </TableCell>
                            ),
                          )}
                        </TableRow>
                        <TableRow>
                          <TableCell>Total Lifting</TableCell>
                          {data?.salesCollectionPerCustomer?.months?.map(
                            (i) => (
                              <TableCell key={i}>
                                {
                                  data?.salesCollectionPerCustomer?.data?.[i]
                                    ?.totalLifting
                                }
                              </TableCell>
                            ),
                          )}
                        </TableRow>
                        <TableRow>
                          <TableCell>No. of Customers</TableCell>
                          {data?.salesCollectionPerCustomer?.months?.map(
                            (i) => (
                              <TableCell key={i}>
                                {
                                  data?.salesCollectionPerCustomer?.data?.[i]
                                    ?.customers
                                }
                              </TableCell>
                            ),
                          )}
                        </TableRow>
                        <TableRow>
                          <TableCell>Average Collection</TableCell>
                          {data?.salesCollectionPerCustomer?.months?.map(
                            (i) => (
                              <TableCell key={i}>
                                {
                                  data?.salesCollectionPerCustomer?.data?.[i]
                                    ?.averageCollection
                                }
                              </TableCell>
                            ),
                          )}
                        </TableRow>
                        <TableRow>
                          <TableCell>Average Lifting</TableCell>
                          {data?.salesCollectionPerCustomer?.months?.map(
                            (i) => (
                              <TableCell key={i}>
                                {
                                  data?.salesCollectionPerCustomer?.data?.[i]
                                    ?.averageLifting
                                }
                              </TableCell>
                            ),
                          )}
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                )}
              </Paper>
              {/* COLLECTION CATEGORY SELECTED MONTH */}
              <Paper sx={{ p: 2, width: '100%' }}>
                <Typography
                  fontSize={18}
                  fontWeight={700}
                  textAlign="center"
                  textTransform="uppercase"
                  pb={1}>
                  {data?.collectionCategoryCurrentMonth?.message}
                </Typography>
                {data?.collectionCategoryCurrentMonth?.data?.length < 1 ? (
                  <NoData />
                ) : (
                  <TableContainer sx={tableStyle}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Location</TableCell>
                          <TableCell>No. of Customers</TableCell>
                          {Object.entries(
                            data?.collectionCategoryCurrentMonth?.categories,
                          )?.map(([k, v]) => {
                            const rMin = v?.min ? `${v?.min + 1}` : '0';
                            const rMax = v?.max <= 999999 ? `${v?.max}` : '∞';
                            return (
                              <TableCell key={k}>
                                {rMin}-{rMax}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {data?.collectionCategoryCurrentMonth?.data?.map(
                          (cc) => (
                            <TableRow>
                              <TableCell>{cc?.location}</TableCell>
                              <TableCell>{cc?.customers}</TableCell>
                              {Object.keys(
                                data?.collectionCategoryCurrentMonth
                                  ?.categories,
                              )?.map((k) => (
                                <TableCell key={`${cc?.location}-${k}`}>
                                  {cc?.[k]}
                                </TableCell>
                              ))}
                            </TableRow>
                          ),
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                )}
              </Paper>
              {/* LIFTING PLAN PER MONTH */}
              <Paper sx={{ p: 2, width: '100%' }}>
                <Typography
                  fontSize={18}
                  fontWeight={700}
                  textAlign="center"
                  textTransform="uppercase"
                  pb={1}>
                  {data?.liftingPlanPerMonth?.message}
                </Typography>
                <ResponsiveContainer width="100%" height={300}>
                  {data?.liftingPlanPerMonth?.data?.length < 1 ? (
                    <NoData />
                  ) : (
                    <BarChart
                      width={500}
                      height={250}
                      data={data?.liftingPlanPerMonth?.data}
                      margin={{
                        top: 20,
                        right: 0,
                        left: 20,
                        bottom: 0,
                      }}>
                      <CartesianGrid vertical={false} />
                      <XAxis
                        dataKey="location"
                        tickLine={false}
                        axisLine={true}
                      />
                      <YAxis tickLine={false} axisLine={false} />
                      <Tooltip allowEscapeViewBox={{ y: true }} />
                      <Legend verticalAlign="bottom" height={36} />

                      {data?.liftingPlanPerMonth?.months?.map((item, index) => (
                        <Bar
                          key={`${item}`}
                          dataKey={item}
                          fill={TotalSKUColors[index % TotalSKUColors.length]}
                        />
                      ))}
                    </BarChart>
                  )}
                </ResponsiveContainer>
              </Paper>
              {/* COLLECTION AMOUNT PER MONTH */}
              <Paper sx={{ p: 2, width: '100%' }}>
                <Typography
                  fontSize={18}
                  fontWeight={700}
                  textAlign="center"
                  textTransform="uppercase"
                  pb={1}>
                  {data?.customerCollectionPerMonth?.message}
                </Typography>
                <ResponsiveContainer width="100%" height={300}>
                  {data?.customerCollectionPerMonth?.data?.length < 1 ? (
                    <NoData />
                  ) : (
                    <BarChart
                      width={500}
                      height={250}
                      data={data?.customerCollectionPerMonth?.data}
                      margin={{
                        top: 20,
                        right: 0,
                        left: 20,
                        bottom: 0,
                      }}>
                      <CartesianGrid vertical={false} />
                      <XAxis
                        dataKey="location"
                        tickLine={false}
                        axisLine={true}
                      />
                      <YAxis tickLine={false} axisLine={false} />
                      <Tooltip allowEscapeViewBox={{ y: true }} />
                      <Legend verticalAlign="bottom" height={36} />

                      {data?.customerCollectionPerMonth?.months?.map(
                        (item, index) => (
                          <Bar
                            key={`${item}`}
                            dataKey={item}
                            fill={TotalSKUColors[index % TotalSKUColors.length]}
                          />
                        ),
                      )}
                    </BarChart>
                  )}
                </ResponsiveContainer>
              </Paper>
              {/* LIFTING AMOUNT PER MONTH */}
              <Paper sx={{ p: 2, width: '100%' }}>
                <Typography
                  fontSize={18}
                  fontWeight={700}
                  textAlign="center"
                  textTransform="uppercase"
                  pb={1}>
                  {data?.customerLiftingPerMonth?.message}
                </Typography>
                <ResponsiveContainer width="100%" height={300}>
                  {data?.customerLiftingPerMonth?.data?.length < 1 ? (
                    <NoData />
                  ) : (
                    <BarChart
                      width={500}
                      height={250}
                      data={data?.customerLiftingPerMonth?.data}
                      margin={{
                        top: 20,
                        right: 0,
                        left: 20,
                        bottom: 0,
                      }}>
                      <CartesianGrid vertical={false} />
                      <XAxis
                        dataKey="location"
                        tickLine={false}
                        axisLine={true}
                      />
                      <YAxis tickLine={false} axisLine={false} />
                      <Tooltip allowEscapeViewBox={{ y: true }} />
                      <Legend verticalAlign="bottom" height={36} />

                      {data?.customerLiftingPerMonth?.months?.map(
                        (item, index) => (
                          <Bar
                            key={`${item}`}
                            dataKey={item}
                            fill={TotalSKUColors[index % TotalSKUColors.length]}
                          />
                        ),
                      )}
                    </BarChart>
                  )}
                </ResponsiveContainer>
              </Paper>
              {/* PLAN ACHIEVEMENT COMPARE COUNT */}
              <Paper sx={{ p: 2, width: '100%' }}>
                <Typography
                  fontSize={18}
                  fontWeight={700}
                  textAlign="center"
                  textTransform="uppercase"
                  pb={1}>
                  {data?.planAchievementCompareCount?.message}
                </Typography>
                {data?.planAchievementCompareCount?.data?.length < 1 ? (
                  <NoData />
                ) : (
                  <TableContainer sx={tableStyle}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Location</TableCell>
                          {data?.planAchievementCompareCount?.months?.map(
                            (m) => (
                              <TableCell key={m} colSpan={3}>
                                {m}
                              </TableCell>
                            ),
                          )}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {data?.planAchievementCompareCount?.data?.map((pc) => (
                          <TableRow>
                            <TableCell>{pc?.location}</TableCell>
                            {data?.planAchievementCompareCount?.months?.map(
                              (m) => (
                                <>
                                  <TableCell key={`${pc?.location}-${m}-1`}>
                                    <Typography color="error.main">
                                      {pc?.[m]?.plan}
                                    </Typography>
                                  </TableCell>
                                  <TableCell key={`${pc?.location}-${m}-2`}>
                                    <Typography color="warning.main">
                                      {pc?.[m]?.collection}
                                    </Typography>
                                  </TableCell>
                                  <TableCell key={`${pc?.location}-${m}-3`}>
                                    <Typography color="success.main">
                                      {pc?.[m]?.lifting}
                                    </Typography>
                                  </TableCell>
                                </>
                              ),
                            )}
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                    <Stack
                      direction="row"
                      sx={{
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                      }}>
                      <SquareIcon fontSize="small" color="error" />
                      <Typography variant="subtitle1" color="error.main">
                        Lifting plan count
                      </Typography>
                      &ensp;
                      <SquareIcon fontSize="small" color="warning" />
                      <Typography variant="subtitle1" color="warning.main">
                        Collection count
                      </Typography>
                      &ensp;
                      <SquareIcon fontSize="small" color="success" />
                      <Typography variant="subtitle1" color="success.main">
                        Lifting count
                      </Typography>
                    </Stack>
                  </TableContainer>
                )}
              </Paper>
              {/* PLAN ACHIEVEMENT COMPARE VALUE */}
              <Paper sx={{ p: 2, width: '100%' }}>
                <Typography
                  fontSize={18}
                  fontWeight={700}
                  textAlign="center"
                  textTransform="uppercase"
                  pb={1}>
                  {data?.planAchievementCompareValue?.message}
                </Typography>
                {data?.planAchievementCompareValue?.data?.length < 1 ? (
                  <NoData />
                ) : (
                  <TableContainer sx={tableStyle}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Location</TableCell>
                          {data?.planAchievementCompareValue?.months?.map(
                            (m) => (
                              <TableCell key={m} colSpan={3}>
                                {m}
                              </TableCell>
                            ),
                          )}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {data?.planAchievementCompareValue?.data?.map((pc) => (
                          <TableRow>
                            <TableCell>{pc?.location}</TableCell>
                            {data?.planAchievementCompareValue?.months?.map(
                              (m) => (
                                <>
                                  <TableCell key={`${pc?.location}-${m}-1`}>
                                    <Typography color="error.main">
                                      {pc?.[m]?.plan}
                                    </Typography>
                                  </TableCell>
                                  <TableCell key={`${pc?.location}-${m}-2`}>
                                    <Typography color="warning.main">
                                      {pc?.[m]?.collection}
                                    </Typography>
                                  </TableCell>
                                  <TableCell key={`${pc?.location}-${m}-3`}>
                                    <Typography color="success.main">
                                      {pc?.[m]?.lifting}
                                    </Typography>
                                  </TableCell>
                                </>
                              ),
                            )}
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                    <Stack
                      direction="row"
                      sx={{
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                      }}>
                      <SquareIcon fontSize="small" color="error" />
                      <Typography variant="subtitle1" color="error.main">
                        Lifting plan amount
                      </Typography>
                      &ensp;
                      <SquareIcon fontSize="small" color="warning" />
                      <Typography variant="subtitle1" color="warning.main">
                        Collection amount
                      </Typography>
                      &ensp;
                      <SquareIcon fontSize="small" color="success" />
                      <Typography variant="subtitle1" color="success.main">
                        Lifting amount
                      </Typography>
                    </Stack>
                  </TableContainer>
                )}
              </Paper>
              {/* WEEKLY COLLECTION STATUS */}
              <Stack component={Paper} sx={{ gap: 2, p: 2, width: '100%' }}>
                <Typography
                  fontSize={18}
                  fontWeight={700}
                  textAlign="center"
                  textTransform="uppercase">
                  {data?.weeklyCollectionStatus?.message}
                </Typography>
                {data?.weeklyCollectionStatus?.data?.map((wc) => (
                  <Paper
                    key={wc?.location}
                    variant="outlined"
                    sx={{ p: 2, width: '100%' }}>
                    <Typography
                      fontSize={18}
                      fontWeight={700}
                      textAlign="center"
                      pb={1}>
                      {wc?.location}
                    </Typography>
                    <Stack
                      direction={{ lg: largeView ? 'column' : 'row' }}
                      gap={2}>
                      <ResponsiveContainer width="100%">
                        {wc?.weekly?.length < 1 ? (
                          <NoData />
                        ) : (
                          <TableContainer sx={tableStyle}>
                            <Table>
                              <TableHead>
                                <TableRow>
                                  <TableCell>Month</TableCell>
                                  {wc?.weekly?.map((i) => (
                                    <TableCell
                                      key={`${wc?.location} ${i?.weekLabel}`}>
                                      {i?.weekLabel}
                                    </TableCell>
                                  ))}
                                  <TableCell>Total</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {wc?.months?.map((monthKey) => (
                                  <TableRow key={`${wc?.location} ${monthKey}`}>
                                    <TableCell>{monthKey}</TableCell>
                                    {wc?.weekly?.map((i) => (
                                      <TableCell
                                        key={`${wc?.location} ${monthKey} ${i?.weekLabel}`}>
                                        {i?.[monthKey]}
                                      </TableCell>
                                    ))}
                                    <TableCell>
                                      {wc?.monthlyTotal?.[monthKey]}
                                    </TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        )}
                      </ResponsiveContainer>
                      <ResponsiveContainer width="100%" height={300}>
                        {wc?.weekly?.length < 1 ? (
                          <NoData />
                        ) : (
                          <BarChart
                            width={500}
                            height={250}
                            data={wc?.weekly}
                            margin={{
                              top: 20,
                              right: 0,
                              left: 20,
                              bottom: 0,
                            }}>
                            <CartesianGrid vertical={false} />
                            <XAxis
                              dataKey="weekLabel"
                              tickLine={false}
                              axisLine={true}
                            />
                            <YAxis tickLine={false} axisLine={false} />
                            <Tooltip allowEscapeViewBox={{ y: true }} />
                            <Legend verticalAlign="bottom" height={36} />

                            {wc?.months?.map((item, index) => (
                              <Bar
                                key={`${wc?.location} ${item}`}
                                dataKey={item}
                                fill={
                                  TotalSKUColors[index % TotalSKUColors.length]
                                }
                              />
                            ))}
                          </BarChart>
                        )}
                      </ResponsiveContainer>
                    </Stack>
                  </Paper>
                ))}
              </Stack>
              {/* PLAN ACHIEVEMENT COMPARE PERCENT */}
              <Paper sx={{ p: 2, width: '100%' }}>
                <Typography
                  fontSize={18}
                  fontWeight={700}
                  textAlign="center"
                  textTransform="uppercase"
                  pb={1}>
                  {data?.planAchievementComparePercent?.message}
                </Typography>
                {data?.planAchievementComparePercent?.data?.length < 1 ? (
                  <NoData />
                ) : (
                  <TableContainer sx={tableStyle}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Location</TableCell>
                          {data?.planAchievementComparePercent?.months?.map(
                            (m) => (
                              <TableCell key={m} colSpan={2}>
                                {m}
                              </TableCell>
                            ),
                          )}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {data?.planAchievementComparePercent?.data?.map(
                          (pc) => (
                            <TableRow>
                              <TableCell>{pc?.location}</TableCell>
                              {data?.planAchievementComparePercent?.months?.map(
                                (m) => (
                                  <>
                                    <TableCell key={`${pc?.location}-${m}-2`}>
                                      <Typography color="warning.main">
                                        {pc?.[m]?.collection
                                          ? `${pc?.[m]?.collection}%`
                                          : ''}
                                      </Typography>
                                    </TableCell>
                                    <TableCell key={`${pc?.location}-${m}-3`}>
                                      <Typography color="success.main">
                                        {pc?.[m]?.lifting
                                          ? `${pc?.[m]?.lifting}%`
                                          : ''}
                                      </Typography>
                                    </TableCell>
                                  </>
                                ),
                              )}
                            </TableRow>
                          ),
                        )}
                      </TableBody>
                    </Table>
                    <Stack
                      direction="row"
                      sx={{
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                      }}>
                      <SquareIcon fontSize="small" color="warning" />
                      <Typography variant="subtitle1" color="warning.main">
                        Collection achievement
                      </Typography>
                      &ensp;
                      <SquareIcon fontSize="small" color="success" />
                      <Typography variant="subtitle1" color="success.main">
                        Lifting achievement
                      </Typography>
                    </Stack>
                  </TableContainer>
                )}
              </Paper>
            </>
          )}
        </>
      )}
    </Stack>
  );
}

const tableStyle = {
  table: {
    tr: {
      th: {
        fontSize: 14,
        padding: 1,
        whiteSpace: 'nowrap',
        '&:first-child': {
          fontSize: 16,
          fontWeight: 600,
        },
      },

      td: {
        fontSize: 14,
        padding: 1,
        whiteSpace: 'nowrap',
      },
    },
    thead: {
      'tr:last-child': {
        th: {
          background: (theme) => theme.palette.primary.main,
          color: '#fff',
          fontSize: 16,
          fontWeight: 600,
        },
      },
    },
  },
};

function NoData() {
  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      height={1}
      width={1}
      p={1}>
      <FindInPageIcon color="disabled" />
      <Typography variant="subtitle2" color="text.disabled">
        No data found
      </Typography>
    </Stack>
  );
}
