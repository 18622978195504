import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import utc from 'dayjs/plugin/utc';
import tz from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(tz);
dayjs.extend(customParseFormat);

/**
 * Get Month Week Range
 * @param {Date} start Range start
 * @param {Date} end Range end
 * @param {String} weekend Weekend day short form
 * @param {String} timezone Timezone
 */
export default function getMonthWeekRange(
  start,
  end,
  weekend = 'Fri',
  timezone = 'Asia/Dhaka',
) {
  const iStart = dayjs(start);
  const iEnd = dayjs(end);

  let iDay = iStart;
  const monthWeeks = {};

  while (iDay.valueOf() <= iEnd.valueOf()) {
    const dayStartTime = iDay.startOf('date').tz(timezone).toDate();
    const dayEndTime = iDay.endOf('date').tz(timezone).toDate();
    const dayOfWeek = iDay.tz(timezone).format('ddd');
    const monthKey = iDay.tz(timezone).format('MMM YYYY');

    if (!monthWeeks[monthKey]) {
      monthWeeks[monthKey] = {};
    }
    if (!monthWeeks[monthKey]['1']) {
      monthWeeks[monthKey]['1'] = { start: dayStartTime };
    }

    Object.entries(monthWeeks[monthKey]).forEach((v, index) => {
      const i = index + 1;
      if (!monthWeeks[monthKey][`${i}`].start) {
        monthWeeks[monthKey][`${i}`].start = dayStartTime;
      }
      if (!monthWeeks[monthKey][`${i}`].end && dayOfWeek === weekend) {
        monthWeeks[monthKey][`${i}`].end = dayEndTime;
        monthWeeks[monthKey][`${i + 1}`] = {};
      }
    });

    iDay = iDay.add(1, 'day');
  }

  Object.entries(monthWeeks).forEach(([m, v]) => {
    const monthEndTime = dayjs
      .tz(m, 'MMM YYYY', timezone)
      .tz(timezone)
      .endOf('month');
    const weeks = Object.values(v);
    weeks[weeks.length - 1].end = monthEndTime.toDate();
  });

  return monthWeeks;
}
